import React, { Component } from "react";
import { connect } from "react-redux";
import { loadPlayers, setPlayerConfig } from "../actions";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing.unit,
    marginRight: theme.spacing.unit * 3,
    minWidth: 300,
  },
});

class PlayerSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: this.props.selectedPlayer,
      loopPlayback: false,
      loop: 0,
      outputs: null,
    };
  }

  handlePlayerChange = (e) => {
    const player = e.target.value;
    this.props.onPlayerChange(player);
    this.setState({ player: player });
  };

  handleLoopPlayback = (e) => {
    const loopPlayback = e.target.checked;
    var numberOfLoops = 0;
    if (loopPlayback) {
      numberOfLoops = -1;
    }
    this.callPlayerConfig(this.state.outputs, numberOfLoops);
    this.setState({ loopPlayback: loopPlayback, loop: numberOfLoops });
  };

  handleOutputs = (e) => {
    var outputsValue = e.target.value;
    var outputs = null;
    if (outputsValue != "") {
      // Format (no spaces and create array of strings seperated by ",")
      outputs = outputsValue.replace(/\s/g, "");
      outputs = outputsValue.split(",");
    }
    this.callPlayerConfig(outputs, this.state.loop);
    this.setState({ outputsValue: outputsValue, outputs: outputs });
  };

  callPlayerConfig = (outputs, loop) => {
    if (outputs != null) {
      this.props.setPlayerConfig({
        outputs: outputs,
        loop: loop,
      });
    } else {
      this.props.setPlayerConfig({
        loop: loop,
      });
    }
  };

  componentDidMount() {
    this.props.reloadPlayers();
  }

  render() {
    const { classes, noLoop } = this.props;
    const playerListItems = this.props.players.map((player) => (
      <MenuItem key={player.playerName} value={player.playerName}>
        {player.playerName}
      </MenuItem>
    ));
    return (
      <form autoComplete="off">
        <FormGroup row>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-simple">Select player</InputLabel>
            <Select
              value={this.state.player}
              onChange={this.handlePlayerChange}
              inputProps={{
                name: "player-select",
                id: "player-select",
              }}
            >
              {playerListItems}
            </Select>
          </FormControl>
          <TextField
            className={classes.formControl}
            id="outputs"
            name="outputs"
            label="Outputs (e.g. light1,light2)"
            value={this.state.outputsValue}
            onChange={this.handleOutputs}
            margin="normal"
          />
          {!noLoop ? (
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={this.state.loopPlayback}
                  onChange={this.handleLoopPlayback}
                  value="loopPlayback"
                />
              }
              label="Loop playback"
            />
          ) : null}
        </FormGroup>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPlayer: state.players.selectedPlayer,
  players: state.players.items,
});

const mapDispatchToProps = (dispatch) => ({
  reloadPlayers: () => dispatch(loadPlayers()),
  setPlayerConfig: (playerConfig) => dispatch(setPlayerConfig(playerConfig)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PlayerSelector)
);
